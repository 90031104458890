<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="color3"
        text
        v-on="on"
      >
        Get Social!
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Let's get social!</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color2 color2Text--text"
          small fab
          @click.stop="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <loading-bar :value="loading"></loading-bar>
      <div class="text-right">
        <v-btn
          color="color3"
          class="xsmall"
          text
          @click.stop="refresh"
        >
          <v-icon>fas fa-sync-alt</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <v-select
          :items="medias"
          v-model="selectedMedia"
          label="Social Media Handle"
          return-object
        ></v-select>
        <v-data-table
          :headers="headers"
          :items="players"
          :loading="loading"
          dense
        >
          <template v-slot:[`item.action`]="{item}">
            <v-btn
              v-if="item.social && item.social[selectedProp]"
              color="color3"
              @click.stop="copyPlayer(item.ppId)"
              text class="xsmall"
            >
              <v-icon>fas fa-copy</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          color="color3"
          text
           @click.stop="copy"
        >
          <v-icon class="mr-2">fas fa-copy</v-icon>
          copy to clipboard
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="snack"
      :color="`${snackCount ? 'success' : 'error'} white--text`"
    >
      {{snackCount ? `${snackCount} ${selectedMedia && selectedMedia.text} handles copied to the clipboard` : `There were no  ${selectedMedia && selectedMedia.text} to copy`}}
      <v-btn text color="white" @click.native="snack = false">Close</v-btn>
    </v-snackbar>
  </v-dialog>
</template>

<script>
import { firstBy } from 'thenby'
import CopyToClipboard from '@/helpers/CopyToClipboard'
export default {
  props: ['players'],
  data () {
    return {
      dialog: false,
      loading: false,
      selectedMedia: null,
      snack: null,
      snackCount: null
    }
  },
  computed: {
    playerIds () {
      return this.players.map(m => m.ppId)
    },
    headers () {
      return [
        { text: 'Name', value: 'name' },
        this.selectedMedia,
        { text: '', value: 'action' }
      ]
    },
    medias () {
      return [
        { text: 'YouTube', value: 'social.youtube' },
        { text: 'Vimeo', value: 'social.vimeo' },
        { text: 'Facebook', value: 'social.facebook' },
        { text: 'Instagram', value: 'social.instagram' },
        { text: 'Twitter', value: 'social.twitter' },
        { text: 'Snapchat', value: 'social.snapchat' },
        { text: 'TikTok', value: 'social.tiktok' }
      ].sort(firstBy('text'))
    },
    dtoString () {
      const data = this.players.map(p => p.social && p.social[this.selectedProp]).filter(f => !!f)
      return data.length && data
    },
    selectedProp () {
      return this.selectedMedia && this.selectedMedia.text.toLowerCase()
    }
  },
  methods: {
    copy () {
      if (!this.dtoString) return
      CopyToClipboard(this.dtoString.join(' '))
        .then(() => {
          this.snackCount = this.dtoString.length
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.snack = true
        })
    },
    playerHandle (id) {
      const player = this.players.find(f => f.ppId === id) && this.selectedProp
      return player && player.social && player.social[this.selectedProp]
    },
    copyPlayer (id) {
      const player = this.players.find(f => f.ppId === id)
      const data = player && player.social && player.social[this.selectedProp]
      if (!data) return
      // `${player.name} ${player.social[this.selectedProp]}`
      CopyToClipboard(`${player.social[this.selectedProp]}`)
        .then(() => {
          this.snackCount = 1
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.snack = true
        })
    },
    init () {
      const toLoad = this.players.filter(f => !f.socialLoaded).map(m => m.ppId)
      if (toLoad.length) {
        this.loadSocial(toLoad)
      }
      if (!this.selectedMedia) this.selectedMedia = this.medias.find(f => f.text === 'Instagram')
    },
    refresh () {
      const toLoad = this.players.map(m => m.ppId)
      if (toLoad.length) {
        this.loadSocial(toLoad)
      }
    },
    loadSocial (ids) {
      if (ids.length) {
        this.loading = true
        this.$VBL.player.getSocial(ids)
          .then(r => {
            r.data.forEach(dto => {
              const player = this.players.find(f => f.ppId === dto.id)
              if (player) {
                player.social = dto.social
                if (player.social && player.social.instagram && !player.social.instagram.startsWith('@')) {
                  player.social.instagram = `@${player.social.instagram}`
                }
                player.socialLoaded = true
              }
            })
          })
          .catch(e => console.log(e))
          .finally(() => { this.loading = false })
      }
    }
  },
  watch: {
    dialog: function (val) {
      if (val) this.init()
    }
  }
}
</script>
